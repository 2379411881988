import * as React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Layout from "../../components/layout"
import SEOComponent from "../../components/seo"
import { Hero } from "../../components/hero"
import PageCTA from "../../components/pageCTA"

export const query = graphql`
  query StaffTemplateNewQuery($id: String) {
    heroImage: file(name: { eq: "staff-banner-handshake" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    contentfulStaffProfile(id: { eq: $id }) {
      id
      name
      suffix
      department
      position
      email
      permalink
      profileImage {
        gatsbyImageData(layout: CONSTRAINED, aspectRatio: 0.75)
      }
      description {
        raw
      }
    }
  }
`

const StaffTemplateNew = ({ data, pageContext }) => {
  const banner = data.heroImage.childImageSharp.gatsbyImageData
  const { name, suffix, position, email, description } =
    data.contentfulStaffProfile || null
  const firstName = name.split(" ")[0]
  const staffImage = data.contentfulStaffProfile.profileImage.gatsbyImageData

  return (
    <Layout>
      <SEOComponent
        title={name}
        description={`Strong Foundations CT staff member ${name} profile`}
      />
      <Hero image={banner} header={`Meet ${firstName}`} />
      <div className="container my-4 mx-auto pt-0 px-4 pb-6">

        <section>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            <div className="text-center space-y-4">
              <GatsbyImage
                className="rounded max-w-xs"
                image={staffImage}
                alt={name}
              />
              <h2 className="text-2xl font-serif">
                {suffix ? `${name} ${suffix}` : `${name}`}
              </h2>
              <p className="text-xl font-serif text-gray-500">{position}</p>
              <a className="block break-words" href={`mailto:${email}`}>
                {email}
              </a>
            </div>
            <div className="md:col-span-2">
              {description && (
                <div className="space-y-4">{renderRichText(description)}</div>
              )}
            </div>
          </div>
        </section>

        <PageCTA
          h2="Ready to find out more?"
          buttonText="Learn more"
          destination="/contact-us/"
        >
          <p className="font-serif text-gray-500 text-xl">
            Review the{" "}
            <Link to="/services/" title="Services">
              services
            </Link>{" "}
            we offer, ask any questions{" "}
            <Link to="/contact-us/" title="Contact us">
              here
            </Link>
            , or call us today!
          </p>
        </PageCTA>
      </div>
    </Layout>
  )
}

export default StaffTemplateNew
